<template>
  <el-tabs v-model="activeName" type="card">
    <el-tab-pane label="费用信息" name="second">
      <div class="headerbut">
        <el-button class="seniorbut" @click="SubmitBut">提交</el-button>
        <!-- <el-button class="ordinarybut">取消</el-button> -->
      </div>
      <div class="box2">
        <div class="queryItem1">
          <div class="queryLabel FontStyle">收支批次号:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.financeOutnumber"
              :disabled="true"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">收支类型:</div>
          <div class="right_inpu">
            <el-select
              v-model="arraydata.financeType"
              class="queryInput"
              :disabled="true"
            >
              <el-option
                v-for="(item, index) in shouzhiList"
                :label="item.name"
                :value="item.financeType"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">费用:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.settlementAmount"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">冲销单据:</div>
          <div class="right_inpu">
            <el-select
              v-model="arraydata.financeDocumentstatusId"
              class="queryInput"
            >
              <el-option
                v-for="(item, index) in chongxiaoList"
                :label="item.documentStatusName"
                :value="item.financeDocumentstatusId"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">冲销单号:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.documentNumber"
              class="queryInput"
            ></el-input>
          </div>
        </div>

        <div class="queryItem1">
          <div class="queryLabel FontStyle">收支备注:</div>
          <div class="right_inpu">
            <el-input v-model="arraydata.comment" class="queryInput"></el-input>
          </div>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { Api } from "@/assets/js/api";
import tool from "@/assets/js/tool";
export default {
  inject: ["reload"],
  data() {
    return {
      activeName: "second",
      input: "",
      arraydata: {
        documentNumber: "", //冲销单号 必填
        financeDocumentstatusId: "", //冲销单据id
        financeDocumentstatusName: "", //冲销单据名称
        settlementAmount: "", //结算金额
        comment: "", //备注
        financeType: 1, //收支类型(0=收入,1=支出)
        storageId: "", //仓库id
      },
      chongxiaoList: [], //冲销列表
      shouzhiList: [
        {
          financeType: 0,
          name: "收入",
        },
        {
          financeType: 1,
          name: "支出",
        },
      ],
      pageType: "add", //新增还是修改
      nid: "", //修改传过来的id
    };
  },
  mounted() {
    //console.log(this.$route.query)
    this.pageType = this.$route.query.pageType;
    this.nid = this.$route.query.nid;
    if (this.pageType == "modify") {
      this.RevenueDetails();
    }
    this.financeDocumentstatusChooseList();
  },
  methods: {
    financeDocumentstatusChooseList() {
      Api.financeDocumentstatusChooseList().then((res) => {
        //console.log(res.data.result)
        this.chongxiaoList = res.data.result;
      });
    },
    //支出详情
    RevenueDetails() {
      Api.otherFinanceOutDetail({ otherFinanceId: this.nid }).then((res) => {
        let arraydata = res.data.result || {};
        delete arraydata.storageName;
        delete arraydata.financeTypeShow;
        delete arraydata.financeOutId;
        delete arraydata.financeOutnumber;
        this.arraydata = arraydata;
      });
    },
    //提交
    SubmitBut() {
      let _this = this;
      let params = JSON.parse(JSON.stringify(this.arraydata));
      let sign = tool.getSign(params);
      params.sign = sign;
      if (this.pageType == "modify") {
        // 修改
        Api.updateOtherFinance(params).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            _this.empty();
            tool.closePage();
            this.reload();
          }
        });
      } else {
        Api.financeInAndOutApply(params).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            _this.empty();
            tool.closePage();
            this.reload();
          }
        });
      }
    },
    //清空列表
    empty() {
      this.arraydata = {
        documentNumber: "", //冲销单号 必填
        financeDocumentstatusId: "", //冲销单据id
        financeDocumentstatusName: "", //冲销单据名称
        settlementAmount: "", //结算金额
        comment: "", //备注
        financeType: 1, //收支类型(0=收入,1=支出)
        storageId: "", //仓库id
      };
    },
  },
};
</script>
<style scoped lang="scss">
.headerbut {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.panel {
  width: 100%;
  margin: 20px auto;
  padding: 100px 20px;
  box-sizing: border-box;
  border: 1px solid slategrey;
}

.box2 {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px #e0e0e0;
  padding: 20px;
  width: 99.5%;
  margin: 0 auto;
  display: flex;
  margin: 10px auto;
  box-sizing: border-box;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  .remarksbox {
    color: #606266;
    width: 50%;
    display: flex;
    padding: 5px 1%;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    .remarkstitle {
      width: 7%;
      line-height: 30px;
    }
    .remarksinp {
      width: 50%;
    }
  }
}
.queryItem1:hover {
  border: 1px solid #f0a261;
  box-shadow: 0px 0px 5px #f0a261;
}
//能输入样式
.queryItem1 {
  min-width: 455px;
  width: 30%;
  margin: 3px 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;

  align-items: center;
  font-size: 14px;
  .FontStyle {
    color: #f00;
    cursor: pointer;
  }
  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .queryLabel {
    width: 100px;
    min-width: 100px;
    color: #606266;
    text-align: right;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .right_inpu_post {
    position: relative;
    .niming {
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 99;
    }
  }
  .right_inpu {
    width: 100%;
  }
  .right_inpu ::v-deep {
    .el-input__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 100%;
    }
  }
  .right_inpu ::v-deep {
    .el-textarea__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 375px;
    }
  }
}
</style>